import React, { Component } from "react";
import styled from "styled-components";
import { color } from "util/color";
import SecurityModal from "components/common/SecurityModal";
import { connect } from "react-redux";
import { logonActions } from "actions/logonAction";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "util/messages";
import { envConfig } from "env-config";
import _ from "lodash";
import { Accordion } from "hsbc-pb-sdk";

const mapStateToProps = state => {
    return {
        terms: state.logon.terms,
        disabledTerms: state.logon.disableTermsBtn,
        selectedLang: state.languageSession.selectedLang
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logonFeachTerms: () => dispatch(logonActions.logonFeachTerms()),
        disableTermsBtn: payload =>
            dispatch(logonActions.disableTermsBtn(payload))
    };
};

class AcceptTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enableAcceptButton: false,
            activeExpandListIndex: []
        };
        this.termsContentRef = React.createRef();
        if (props.storeThis) {
            props.storeThis("AcceptTerms", this);
        }
    }

    componentDidMount() {
        window.tealiumHub.PT008();
        this.props.logonFeachTerms();
        setTimeout(() => {
            document.getElementById("hsbcLogo").focus();
        }, 1000);
        sessionStorage.removeItem("isRegistration");
    }

    isActive = index => _.includes(this.state.activeExpandListIndex, index);

    handleClickMultiple = index => {
        let activeIndexList = this.state.activeExpandListIndex;
        activeIndexList = _.includes(activeIndexList, index)
            ? _.without(activeIndexList, index)
            : _.concat(activeIndexList, index);
        this.setState({ activeExpandListIndex: activeIndexList });
    };

    scrollDownDetect = () => {
        const o = this.termsContentRef.current;
        if (
            o.offsetHeight + o.scrollTop === o.scrollHeight &&
            !this.state.enableAcceptButton
        ) {
            this.setState({
                enableAcceptButton: true
            });
        }
    };

    submit = () => {
        const formInput = window.com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(
            {
                version: envConfig.tcVersion,
                date: new Date()
            }
        );
        this.props.disableTermsBtn(true);
        window.tealiumHub.ET014("acceptTerms.label.accept");
        this.acceptFN(formInput);
    };

    componentWillMount() {
        this.props.disableTermsBtn(false);
    }
    render() {
        const splitedParagraphArray = this.props.terms.termText.split("\n");
        splitedParagraphArray.map((item, i) => (
            <p className="terms-content-paragraph" key={`paragraph-${i}`}>
                {item}
            </p>
        ));

        const isInUS = envConfig.realm === "US" ? true : false;
        const isInCH = envConfig.realm === "CH" ? true : false;

        const selectedCnLang =
            this.props.selectedLang &&
            this.props.selectedLang.indexOf("zh") !== -1;

        let registrationTerms =
            messages[this.props.selectedLang].registrationTerms;

        if (envConfig.realm === "LU") {
            registrationTerms =
                messages[this.props.selectedLang].registrationTermsLUX;
        } else if (envConfig.realm === "CH") {
            registrationTerms =
                messages[this.props.selectedLang].registrationTermsCH;
        }

        return (
            <AcceptTermsContainer
                isInUS={isInUS}
                selectedCnLang={selectedCnLang}
                isInCH={isInCH}
            >
                <SecurityModal
                    title="acceptTerms.title"
                    ariaTitle="acceptTerms.ariaTitle"
                    onConfirmationClick={this.submit}
                    confirmationBtnTitle="acceptTerms.label.accept"
                    dialogWidth="768px"
                    disabledButton={this.props.disabledTerms || false}
                    customizedClass="reg-t-c-modal"
                >
                    <div className="terms-content" ref={this.termsContentRef}>
                        <Accordion fluid exclusive={false}>
                            {registrationTerms.map((item, index) => (
                                <div key={"id" + index}>
                                    <Accordion.Header
                                        title={item.label}
                                        index={index}
                                        onClick={() =>
                                            this.handleClickMultiple(index)
                                        }
                                        active={this.isActive(index)}
                                    />
                                    <Accordion.Content
                                        active={this.isActive(index)}
                                    >
                                        <div
                                            className="accordion-container"
                                            dangerouslySetInnerHTML={{
                                                __html: item.html
                                            }}
                                        ></div>
                                    </Accordion.Content>
                                </div>
                            ))}
                        </Accordion>
                        {envConfig.realm === "UK" ? (
                            <div className="alm-onboard-tip">
                                <FormattedMessage id="accept.terms.alm.tip" />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </SecurityModal>
            </AcceptTermsContainer>
        );
    }
    startSession = () => {
        console.log("startSession");
    };

    promiseFormInput = payload => {
        return new Promise((accept, reject) => {
            this.setPromiseHandlers(accept, reject);
        });
    };

    setPromiseHandlers(acceptFN, rejectFN) {
        this.acceptFN = acceptFN;
        this.rejectFN = rejectFN;
    }

    endSession = () => {
        console.log("endSession");
    };

    onContinue = payload => {
        console.log("onContinue");
    };

    onError = payload => {
        window.globalHistory.push({
            pathname: "/globalError/noConnection?step=009",
            state: { errorCode: "noConnection" }
        });
    };
}

const AcceptTermsContainer = styled.div`
    position: relative;
    .responsive-padding {
        padding: 30px 50px 20px 50px;
        @media (max-width: 414px) {
            padding: 26px 20px 20px 20px;
        }
    }
    .reg-t-c-modal {
        @media (min-width: 1366px) {
            width: 1024px !important;
        }
        @media (min-width: 768px) and (max-width: 1365px) {
            width: 768px !important;
        }
        @media (min-width: 768px) and (max-width: 1023px) {
            width: 650px !important;
        }
    }
    .terms-content {
        margin-top: 30px;
        overflow-y: auto;
        overflow-x: hidden;

        @media (max-width: 320px) {
            min-height: 185px;
        }
        @media (min-width: 321px) and (max-width: 768px) {
            min-height: 300px;
        }
        @media (min-width: 769px) {
            height: ${props => {
                if (props.isInUS) {
                    return "238px";
                }
                return props.selectedCnLang ? "303px" : "268px";
            }};
        }
        @media (min-width: 1024px) {
            height: 400px;
        }
        color: #252525;
        font-size: 14px;
        line-height: 17px;
        .terms-content-paragraph {
            margin-bottom: 17px;
        }
        .hsbc-ui.accordion .header .angle.icon {
            color: rgb(118, 118, 118);
            font-size: 22px;
        }
    }

    .alm-onboard-tip {
        margin-top: 15px;
        font-size: 16px;
        line-height: 24px;
    }

    .contentmodal--header {
        font-size: 28px;
        font-weight: 100;
        font-family: UniversNextForHSBC, Helvetica Neue, Helvetica, Arial,
            sans-serif;
        line-height: 36px;
        margin: 0px;
        text-indent: 0;
        .title--wrapper {
            display: inline-block;
        }
        span {
            font-family: UniversNextForHSBC, Helvetica Neue, Helvetica, Arial,
                sans-serif;
            font-weight: 100;
        }
    }

    .contentmodal--footer {
        .terms-declinebtn {
            cursor: pointer;
            height: 46px;
            padding: 0 20px;
            font-size: 16px;
            line-height: 17px;
            border: none;
            align-self: flex-end;
            background-color: ${color.white};
            color: ${color.text_color_dark};
            border: 1px solid #767676;
        }
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(AcceptTerms));
