import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { longerThan, lessThan } from "util/validator";
import { color } from "util/color";
import { history } from "util/history";
import SecurityModal from "components/common/SecurityModal";
import SecurityTextInput from "components/common/SecurityTextInput";
import WarningMsg from "components/common/WarningMsg";
import { resetPasswordActions } from "actions/resetPasswordAction";
import { updateLockedAccountInfo } from "actions/lockedAccountAction";
import { injectIntl } from "react-intl";
import { lockedTypeActions } from "../../actions/lockedTypeAction";

const mapStateToProps = state => {
    return {
        validateUsernameData: state.resetPassword.validateUsername,
        error: state.resetPassword.error,
        disabledButton: state.resetPassword.isLoading,
        otpStatusResetPWD: state.resetPassword.otpStatus,
        securityQuestionStatusResetPWD: state.resetPassword.seqQsStatus,
        isLastAttempt: state.logon.isLastAttempt
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateLockedAccountInfo: error =>
            dispatch(updateLockedAccountInfo(error)),
        isLoading: payload =>
            dispatch(resetPasswordActions.validateUsernameIsLoading(payload)),
        updateUserLockedType: lockedType => {
            dispatch(lockedTypeActions.updateLockedType(lockedType));
        }
    };
};

class SecurityQuestion extends Component {
    constructor(props) {
        super();
        this.state = {
            answer: "",
            answerWarningMsg: null,
            securityQuestionObj: {}
        };
        if (props.storeThis) {
            props.storeThis("SecurityQuestion", this);
        }
        window.tealiumHub.PT016();
    }
    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            answerWarningMsg: null
        });
    };

    accountLocked = () => {
        this.props.updateUserLockedType("QUESTION");
        console.log(
            "account is locked but SecurityQuestion DONT REACT TO THIS"
        );
    };
    validateInput = () => {
        const { answer } = this.state;
        let validationFailMsg =
            longerThan(
                answer,
                3,
                this.props.intl.formatMessage({
                    id: "securityQuestion.name"
                })
            ) ||
            lessThan(
                answer,
                30,
                this.props.intl.formatMessage({
                    id: "securityQuestion.name"
                })
            );
        if (validationFailMsg) {
            validationFailMsg = this.props.intl.formatMessage({
                id: "securityQuestion.warning.incorrect"
            });
        } else {
            const quesion1Obj = window.com.ts.mobile.sdk.SecurityQuestionAndAnswer.createAnswerToQuestion(
                this.state.securityQuestionObj,
                window.com.ts.mobile.sdk.SecurityQuestionAnswer.createWithText(
                    this.state.answer
                )
            );
            const inputResponse = window.com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(
                window.com.ts.mobile.sdk.SecurityQuestionInputResponse.createSecurityQuestionAnswersInputResponse(
                    [quesion1Obj]
                )
            );
            this.props.isLoading(true);
            this.acceptFN(inputResponse);
            this.securityQuestionComp.clearValue();
        }
        this.setState(previousState => {
            return {
                ...previousState,
                answerWarningMsg: validationFailMsg
            };
        });
    };

    submitAnswer = () => {
        this.validateInput();
        window.tealiumHub.ET036("securityQuestion.label.submit");
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.error !== this.props.error) {
            console.log(this.props.error);
            if (this.props.error === 5) {
                window.tealiumHub.ET037("securityQuestion.warning.incorrect");
            }
        }
        if (prevState.answerWarningMsg !== this.state.answerWarningMsg) {
            if (this.state.answerWarningMsg) {
                window.tealiumHub.ET037(this.state.answerWarningMsg);
            }
        }
    }

    render() {
        const { answerWarningMsg } = this.state;
        let answerErrorComp = null;
        let lastAttemptComp = null;

        if (answerWarningMsg !== null && answerWarningMsg) {
            answerErrorComp = (
                <WarningMsg
                    id="answer_error"
                    warningMsg={this.state.answerWarningMsg}
                />
            );
        }

        if (this.props.isLastAttempt && !answerErrorComp) {
            lastAttemptComp = (
                <DisplayLastAttamptContainer>
                    <WarningMsg
                        id="last_attempt"
                        warningMsg={"otp.last.attampt.tip"}
                    />
                </DisplayLastAttamptContainer>
            );
        }

        const textId =
            `SecurityQuestion.Question.` + this.state.securityQuestionObj._id;
        const text = this.props.intl.formatMessage({ id: textId });
        return (
            <SecurityModal
                title="securityQuestion.title"
                ariaTitle="securityQuestion.ariaTitle"
                confirmationBtnTitle="securityQuestion.label.submit"
                onConfirmationClick={this.submitAnswer}
                disabledButton={this.props.disabledButton || false}
            >
                <SecurityQuestionContainer>
                    <div className="content--main">{text}</div>
                    <SecurityTextInput
                        type="text"
                        name="answer"
                        id="answer"
                        hideLabel={true}
                        ariaDescribedby="answer_error"
                        label="createSecurityQuestions.label.firstQuestion"
                        title={textId}
                        onChange={this.handleChange}
                        errorComp={answerErrorComp}
                        onFocus={touched => {
                            if (!touched) {
                                window.tealiumHub.ET035();
                            }
                        }}
                        onRef={comp => (this.securityQuestionComp = comp)}
                    />
                    {lastAttemptComp}
                </SecurityQuestionContainer>
            </SecurityModal>
        );
    }

    startSession = () => {
        console.log("startSession");
    };

    setPromiseHandlers(acceptFN, rejectFN) {
        this.acceptFN = acceptFN;
        this.rejectFN = rejectFN;
    }
    promiseInput = payload => {
        return new Promise((accept, reject) => {
            this.setPromiseHandlers(accept, reject);
        });
    };
    endSession = () => {
        console.log("=======Security Session END=======");
    };

    onContinue = payload => {
        console.log(
            "SecurityQuestion :: onContinue ",
            this.constructor.name,
            payload
        );

        if (payload) {
            this.props.isLoading(false);
        }
    };
    onError = payload => {
        console.log("onError ", this.constructor.name, payload);
        history.push({
            pathname: "/globalError/noConnection?step=029",
            state: { errorCode: "noConnection" }
        });
    };

    setInputStep = (currentStepIndex, maxStep, currentStep) => {
        this.props.isLoading(false);
        const securityQuestionObj = currentStep.getSecurityQuestions()[0];
        this.setState({
            securityQuestionObj,
            answer: ""
        });
    };
    promiseRecoveryForError = (
        errorObject,
        validRecoveries,
        defaultRecovery
    ) => {
        console.log(
            "ResetPasswordSecurityQuestion:: ERROR!!",
            errorObject,
            validRecoveries,
            defaultRecovery
        );
        console.log("=======STORE SEQ STATUS==========");
        this.props.isLoading(false);

        this.accountLocked();
        return Promise.resolve(
            window.com.ts.mobile.sdk.AuthenticationErrorRecovery
                .SelectAuthenticator
        );
    };
}
const SecurityQuestionContainer = styled.div`
    padding-bottom: 40px;
    .content--main {
        line-height: 24px;
        margin-top: 30px;
        margin-bottom: 7px;
    }
    input {
        height: 40px;
        color: ${color.text_color_dark};
    }
    .block--warning-msg {
        p {
            font-size: 14px !important;
        }
    }
`;
const DisplayLastAttamptContainer = styled.div.attrs({
    className: "last--attempt--reset--poassword--contianer"
})`
       
        margin-bottom: 20px;
        .hsbc-ui.notification.inline{
            padding:0px !important; 
            p{
                font-size:14px !important;
            }
        }
   }
  `;
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(SecurityQuestion));
