import React, { Component } from "react";
import _ from "lodash";
import GenericOTP from "components/OTPVerify/GenericOTP";
import StepCreatePassword from "components/ResetPassword/StepCreatePassword";
import ForgotPassword from "components/ResetPassword/ForgotPassword";
import SecurityQuestion from "components/ResetPassword/SecurityQuestion";
import Loading from "components/common/Loading";
import { changeIdleTimeOutValid } from "util/service";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { languageActions } from "actions/languageAction";
import { sendCodeIsLoading } from "actions/sendCodeAction";

const mapStateToProps = state => {
    return {
        error: state.logon.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendCodeIsLoading: payload => dispatch(sendCodeIsLoading(payload)),
        hideSelectOption: isHideLanguageSelect =>
            dispatch(languageActions.hideSelectOption(isHideLanguageSelect))
    };
};

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: "FORGOT_PASSWORD"
        };
        this.mappedStepToComponent = this.mappingStepToComponent();
        this.loadedComponents = {};
    }

    mappingStepToComponent = () => {
        return [
            {
                step: "FORGOT_PASSWORD",
                component: (
                    <ForgotPassword
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                        hideSelectOption={this.props.hideSelectOption}
                        invokeResetPasswordPolicy={
                            this.props.invokeResetPasswordPolicy
                        }
                        loadedComponents={this.loadedComponents}
                        that={this}
                    />
                )
            },
            {
                step: "SECURITY_QUESTION",
                component: (
                    <SecurityQuestion
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                        invokeResetPasswordPolicy={
                            this.props.invokeResetPasswordPolicy
                        }
                        afterLoad={this.props.afterLoad}
                        loadedComponents={this.loadedComponents}
                        that={this}
                    />
                )
            },
            {
                step: "GENERIC_OTP",
                component: (
                    <GenericOTP
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                        keyPath="Reset_Password"
                    />
                )
            },
            {
                step: "CREATE_PASSWORD",
                component: (
                    <StepCreatePassword
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                    />
                )
            }
        ];
    };
    storeThis = (name, that) => {
        this.loadedComponents[name] = that;
    };
    componentDidMount() {
        this.props.sendCodeIsLoading(false);
        this.jumpTo("FORGOT_PASSWORD");
        this.props.afterLoad(this.loadedComponents, this);
        changeIdleTimeOutValid(true);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentStep !== this.state.step) {
            this.setState((prevState, props) => {
                return { ...prevState, step: nextProps.currentStep };
            });
        }
    }
    jumpTo = step => {
        const renderedComponent = _.find(
            this.mappedStepToComponent,
            item => step === item.step
        );
        this.setState({
            renderedComponent: renderedComponent.component
        });
    };
    render() {
        if (!this.state.renderedComponent) {
            return <Loading dialogHeight="334px" />;
        }
        return (
            <div style={{ zIndex: "1" }}>
                {this.state.renderedComponent}
                {""}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ResetPassword));
