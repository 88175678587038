import React, { Component } from "react";
import styled from "styled-components";
import { color } from "util/color";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Loader } from "hsbc-pb-sdk";
import LogoMark from "components/common/LogoMark";

class SecurityModal extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setTimeout(() => {
            document.getElementById("hsbcLogo") &&
                document.getElementById("hsbcLogo").focus();
        }, 500);
        document.addEventListener("keydown", this.onkeydown);
    }

    onkeydown = e => {
        const activeElement = document.activeElement
            ? document.activeElement.nodeName
            : "";
        if (
            e.keyCode === 13 &&
            (activeElement === "INPUT" || activeElement === "BODY")
        ) {
            this.props.onConfirmationClick(e.keyCode);
        }
    };

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onkeydown);
    }

    render() {
        const {
            title,
            title2,
            ariaTitle = "global.notext",
            titleIcon,
            confirmationBtnDisabled,
            customizedFooter,
            onConfirmationClick,
            confirmationBtnTitle,
            confirmationBtnStyle,
            dialogWidth,
            children,
            disabledButton,
            hideButton
        } = this.props;
        const { currentStep, customizedClass } = this.props;
        const isLoginPage =
            currentStep && currentStep === "loginPage" ? true : false;
        const titles =
            title2 && title2 !== "" ? <FormattedMessage id={title2} /> : "";
        const buttonEle = confirmationBtnTitle ? (
            <Button
                secondary
                onClick={onConfirmationClick}
                onKeyDown={e => this.onkeydown(e)}
            >
                <FormattedMessage id={confirmationBtnTitle} />
            </Button>
        ) : null;
        const isHideButton = disabledButton ? (
            <Button secondary>
                <Loader
                    className="button icon btn--loader"
                    size="small"
                ></Loader>
            </Button>
        ) : (
            buttonEle
        );
        return (
            <SecurityModalContainer
                confirmationBtnStyle={confirmationBtnStyle}
                dialogWidth={dialogWidth}
                titleIcon={titleIcon}
                isLoginPage={isLoginPage}
                hideBorderTop={title && title.indexOf("success") !== -1}
                confirmationBtnDisabled={confirmationBtnDisabled}
                className={
                    customizedClass ? customizedClass : "responsive-padding"
                }
            >
                <LogoMark isLoginPage={isLoginPage} />
                <div className="contentmodal--body">
                    <div className="contentmodal--icon">{titleIcon || ""}</div>
                    <div style={{ width: "100%" }}>
                        <h1
                            aria-label={this.props.intl.formatMessage({
                                id: ariaTitle
                            })}
                            key="header"
                            className="contentmodal--header"
                        >
                            {/* <FormattedMessage id={title} /> */}
                            {title ? <FormattedMessage id={title} /> : ""}
                        </h1>
                        {title2 ? (
                            <h1 key="header2" className="contentmodal--header">
                                {titles}
                            </h1>
                        ) : (
                            ""
                        )}
                        <div key="content" className="contentmodal--content">
                            {children}
                        </div>
                    </div>
                </div>
                <div key="buttons" className="contentmodal--footer">
                    <div className="contentmodal--footer-left">
                        {customizedFooter}
                    </div>
                    {hideButton ? "" : isHideButton}
                </div>
            </SecurityModalContainer>
        );
    }
}
export default injectIntl(SecurityModal);
const SecurityModalContainer = styled.div`
    box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14);
    background-color: ${props =>
        props.isLoginPage ? "transparent" : color.white};
    position: relative;
    color: ${color.text_color_dark};
    margin: 20px;
    .hsbc--logo {
        img {
            margin-left: -6%;
        }
    }
    @media (max-width: 320px) {
        margin: 20px;
        padding: 10px;
        width: 280px;
        word-break: break-word;
        .divBody.bodyExpanded {
            .table_container {
                width: 100%;
                overflow: scroll;
            }
            max-width: 264px;

            overflow: hidden;
        }
    }
    @media (min-width: 321px) and (max-width: 413px) {
        margin: 20px;
        padding: 15px;
        width: 335px;
        word-break: break-word;
        .divBody.bodyExpanded {
            .table_container {
                width: 100%;
                overflow: scroll;
            }
            max-width: 319px;
            overflow: hidden;
        }
    }
    @media (min-width: 414px) and (max-width: 768px) {
        margin: 20px;
        padding: 26px 35px 40px 35px;
        width: 374px;
        word-break: break-word;
        .divBody.bodyExpanded {
            .table_container {
                width: 100%;
                overflow: scroll;
            }
            max-width: 319px;
            overflow: hidden;
        }
    }
    @media (min-width: 769px) and (max-width: 2000px) {
        padding: ${props =>
            props.isLoginPage ? "50px 20px 40px 20px" : "50px 40px 40px 40px"};
        margin-left: 0px;
        width: ${props => props.dialogWidth || "454px"};
        max-width: ${props => props.dialogWidth || "454px"};
        word-break: break-word;
    }

    @media (min-width: 2000px) {
        padding: ${props =>
            props.isLoginPage ? "50px 20px 40px 20px" : "50px 40px 40px 40px"};
        margin-left: 0px;
        width: 40%;
    }

    @media (min-width: 769px) and (max-width: 898px) {
        margin: 20px 0px 0px 0px;
    }
    @media (min-width: 898px) and (max-width: 907px) {
        margin-bottom: 0px;
    }
    .contentmodal--body {
        display: flex;
        justify-content: flex-start;

        .icon {
            color: ${color.hsbc_warning_red};
        }
        .success {
            color: ${color.hsbc_success_green};
            font-size: 30px;
        }
    }
    .contentmodal--icon {
        .icon {
            font-size: 42px;
            margin-right: 25px;
        }
        .warning {
            color: ${color.hsbc_warning_yellow};
        }
    }
    .contentmodal--header {
        font-size: 27px;
        font-weight: 100;
        line-height: 36px;
        margin: 0px;
        color: #333333;
        white-space: pre-line;
        .title--wrapper {
            display: inline-block;
        }
        span {
            font-family: UniversNextForHSBC, Helvetica Neue, Helvetica, Arial,
                sans-serif;
            font-weight: 300;
            line-height: 40px;
        }

        @media (min-width: 1024px) {
            font-size: 31px;
        }
    }

    .contentmodal--content {
        font-size: 16px;
        line-height: 19px;
        font-weight: normal;
    }

    .contentmodal--footer {
        width: 100%;
        border-top: ${props =>
            props.hideBorderTop ? "0px" : "1px solid #d7d8d6"};
        padding-top: 20px;
        display: flex;
        @media (max-width: 414px) {
            justify-content: space-between;
        }

        @media (min-width: 415px) {
            justify-content: ${props =>
                props.hideBorderTop ? "flex-start" : "space-between"};
        }

        font-size: 16px;

        .btn--confirm {
            display: ${props =>
                props.confirmationBtnDisabled ? "none" : "block"};
            ${props => {
                if (props.confirmationBtnStyle) {
                    return props.confirmationBtnStyle;
                }
                return `@media (max-width: 414px) {
                    cursor: pointer;
                    padding: 14px 20px 16px 20px;
                    background-color: ${color.hsbc_red};
                    color: ${color.white};
                    font-weight: normal;
                    line-height: 16px;
                    border: none;
                    width: ${props.hideBorderTop ? "100%" : "auto"};
                }
                @media (min-width: 415px) {
                    cursor: pointer;
                    padding: 14px 20px 16px 20px;
                    background-color: ${color.hsbc_red};
                    color: ${color.white};
                    font-weight: normal;
                    line-height: 16px;
                    border: none;
                    width: ${props.hideBorderTop ? "40%" : "auto"};
                }`;
            }};
        }

        .btn--loader {
            background-color: #730014;
            border: none;
            padding-top: 0px;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 0px;
            height: 46px;
            cursor: default;
            .children {
                margin-top: 0px;
            }
            .spinner {
                border: 2px solid white;
                border-right-color: transparent;
            }
        }
        .btn--loader:before {
            padding-top: 0px;
        }

        .btn--confirm:hidden {
            display: none;
        }
    }

    .hsbc-ui.button.secondary {
        min-width: 115px;
    }

    @media (min-width: 1366px) {
        .hsbc-ui.input {
            max-width: 100%;
        }
    }
`;
