export const initialState = {
    error: {}
};

export function lockedAccount(state = initialState, action) {
    if (action.type === "UPDATE_LOCKED_ACCOUNT_INFO") {
        return {
            ...state,
            error: action.error
        };
    }
    return state;
}
