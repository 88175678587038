import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import "./app.scss";

import AppHeader from "components/common/AppHeader";
import AppFooter from "components/common/AppFooter";
import LoginHelper from "components/LoginHelper";
import ModalPage from "components/ModalPage";
import ResetPassword from "components/ResetPassword";
import Registration from "components/Registration";
import Logon from "components/Logon";
import RestrictedAccess from "components/Exceptions/RestrictedAccess";
import UnsupportedBrowser from "components/Exceptions/UnsupportedBrowser";
import ErrorCode from "components/Exceptions/ErrorCode";
import CommonLockedAccount from "components/Exceptions/CommonLockedAccount";
import NoContactDetails from "components/Exceptions/NoContactDetails";
import AcceptTerms from "components/Logon/AcceptTerms";
import { history } from "util/history";
import {
    promiseInitializedTransmitSdk,
    unSupportedBrowsers,
    switchselectedLangForDocTitle
} from "util/service";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { envConfig } from "env-config";
import GvResult from "components/GvResult/GvResult";
import GvError from "components/GvResult/GvError";
import GvSSOError from "components/GvResult/GvSSOError";
import GvMessage from "components/GvResult/GvMessage";
import AppBackgroundKaleido from "components/common/AppBackgroundKaleido";
import CookieNotice from "components/CCM/CookieNotice";
import LanguageSelectContainer from "components/common/LanguageSelectContainer";
import { lockedTypeActions } from "./actions/lockedTypeAction";
import { logonActions } from "./actions/logonAction";
import { currentOTPTypeActions } from "./actions/currentOTPTypeAction";
import { resetPasswordActions } from "actions/resetPasswordAction";
import { emailOtpLit } from "actions/contactOptionsAction";
import emailOTPcfg from "./emailOTP-config";

const isRealmCH = envConfig.realm === emailOTPcfg.realm;

const mapStateToProps = state => {
    return {
        selectedLang: state.languageSession.selectedLang,
        lockedType: state.lockedType.type
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchAction: dispatch,
        resetState: () => {
            dispatch(lockedTypeActions.resetState());
        },
        updateCurrentOTPType: otpType => {
            dispatch(currentOTPTypeActions.updateCurrentOTPType(otpType));
        },
        isLoading: payload =>
            dispatch(resetPasswordActions.validateUsernameIsLoading(payload)),
        isMockLoginJourney: isMockLogin => {
            dispatch(logonActions.isMockLogin(isMockLogin));
        },
        isLastAttemptTime: isLastAttempt => {
            dispatch(logonActions.isLastAttempt(isLastAttempt));
        },
        emailOtpLit: emailAddress => {
            dispatch(emailOtpLit(emailAddress));
        },
        isPendingResponse: pending => {
            dispatch(logonActions.isPendingResponse(pending));
        }
    };
};

const makeLogoutRequest = async journey => {
    await window.transmitSDK.logout();
};

const isResetPassword = "/resetPassword";
const currentSessionId = "_currentSession._sessionId";
const pathForGenericLock = "/commonLockedAccount/genericAccountLock";
class App extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            registrationComponents: null,
            logonComponents: null,
            resetPasswordComponents: null,
            currentRegistrationStep: "",
            currentLogonStep: "",
            currentResetpasswordStep: ""
        };
        if (props.storeThis) {
            props.storeThis("APP", this);
        }
    }

    initRegistrationSdk = () => {
        this.prepareSDK().then(sdk => {
            const existingSession = _.get(
                window.transmitSDK,
                currentSessionId,
                false
            );
            if (existingSession) {
                makeLogoutRequest("Registration");
            }
            sdk.clearAllData();
        });
    };

    caseBussinessUrl = url => {
        return (
            url.indexOf("api/v2") !== -1 ||
            url.indexOf("/rest/hsbc/SecurityContext") !== -1
        );
    };

    caseTealiumUrl = url => {
        return (
            url.indexOf("dpm.demdex.net") !== -1 ||
            url.indexOf("sc.omtrdc.net") !== -1 ||
            url.indexOf("tealiumiq.com") !== -1
        );
    };

    caseNotBussnessUrl = url => {
        if (!this.caseBussinessUrl(url)) {
            history.push({
                pathname: "/globalError/noConnection?step=004",
                state: {
                    errorCode: "noConnection"
                }
            });
        }
    };

    caseParsedBody = (parsedBody, url) => {
        const lockedCheck = _.get(
            parsedBody,
            'data.failure_data.reason.data["LOCK-CHECK"]',
            false
        );
        const isLockedQuestion =
            parsedBody.error_code &&
            _.get(parsedBody, "data.additional_data.locked", false);
        const isLockedOTP = parsedBody.errorType === "otplock";
        const isActive =
            _.get(
                parsedBody,
                'data.failure_data.reason.data["LOCK-CHECK"]',
                false
            ) === "ACTIVE";
        switch (lockedCheck) {
            case "LOCKED_GLOBAL":
            case "LOCKED_QUESTION":
            case "LOCKED_PASSWORD":
            case "LOCKED_OTP":
                return;
            default:
                console.log("default");
        }
        console.log("xhr parsed response body", parsedBody);

        if (
            String(parsedBody.error_code) === "4001" ||
            String(parsedBody.error_code) === "4002" ||
            String(parsedBody.error_code) === "4005"
        ) {
            if (!(isLockedQuestion || isLockedOTP) || !isActive) {
                console.log("xhr.open push to noconnection");
                this.caseNotBussnessUrl(url);
            }
        } else if (String(parsedBody.error_code) === "Link_Error") {
            console.log("LOGIN GV ERROR FOLLOW");
            history.push("/gvError");
            return;
        }
    };

    caseUrl = url => {
        if (!this.caseBussinessUrl(url)) {
            if (this.caseTealiumUrl(url)) {
                console.log("Ignore tealium request failed error");
                return;
            } else {
                history.push({
                    pathname: "/globalError/noConnection?step=002",
                    state: { errorCode: "noConnection" }
                });
            }
        }
        console.log("xhr.open push to noConnection");
    };

    caseResponseStatus = (_this, parsedBody, url) => {
        if (
            (_this.status >= 200 && _this.status < 300) ||
            _this.status === 304
        ) {
            console.log(
                "xhr.open response code is valid: no error, request suc!"
            );
        } else {
            if (
                typeof parsedBody === "object" &&
                parsedBody.error_code &&
                String(parsedBody.error_code) === "Link_Error"
            ) {
                console.log("LOGIN GV ERROR FOLLOW");
                history.push("/gvError");
            } else {
                this.caseUrl(url);
            }
        }
    };

    caseRequestResponse = (_this, url) => {
        const currentUrl = window.location.href;

        if (
            _this.responseText.indexOf("RESET_COMPLETE") >= 0 ||
            _this.responseText.indexOf("END_OF_REGISTRATION") >= 0 ||
            _this.responseText.indexOf("USER_NOT_RECOGNISED") >= 0 ||
            _this.responseText.indexOf("USER_ALREADY_REGISTERED") >= 0
        ) {
            console.log("xhr no error, request suc!");
        } else if (currentUrl.indexOf("loginHelper") === -1) {
            let parsedBody;
            sessionStorage.setItem("statuscode", _this.status);
            try {
                parsedBody = _this.responseText
                    ? JSON.parse(_this.responseText)
                    : {};
            } catch (e) {
                if (this.caseBussinessUrl(url)) {
                    history.push({
                        pathname: "/globalError/noConnection?step=001",
                        state: { errorCode: "noConnection" }
                    });
                }
                return;
            }
            if (typeof parsedBody === "object") {
                this.caseParsedBody(parsedBody, url);
            }
            this.caseResponseStatus(_this, parsedBody, url);
        }
    };

    componentDidMount() {
        console.log("Current Environment is ::", envConfig.id);
        const selectedLang =
            this.props.selectedLang || localStorage.getItem("selectedLang");

        switchselectedLangForDocTitle(selectedLang);

        unSupportedBrowsers(history);

        if (history.location.pathname === "/") {
            history.push("/logon");
        }
        this.initRegistrationSdk();
        window.globalHistory = this.props.history;
        (function(_this) {
            let origOpen = XMLHttpRequest.prototype.open;
            XMLHttpRequest.prototype.open = function(method, url) {
                console.log("xhr request started!");

                if (_this.caseBussinessUrl(url)) {
                    _this.props.isPendingResponse(true);
                }

                this.addEventListener("loadend", function() {
                    console.log("xhr request completed!");
                    console.log("xhr response state::", this.readyState);

                    if (_this.caseTealiumUrl(url)) {
                        return;
                    }

                    if (_this.caseBussinessUrl(url)) {
                        _this.props.isPendingResponse(false);
                    }

                    _this.caseRequestResponse(this, url);
                });
                origOpen.apply(this, arguments);
            };
        })(this);
    }

    prepareSDK = () => {
        this.props.isPendingResponse(true);
        promiseInitializedTransmitSdk().then(sdk => {
            sdk.setUiHandler(this);
            this.props.isPendingResponse(false);
        });
        return promiseInitializedTransmitSdk();
    };

    createFormSession = (formId, payload) => {
        const currentJourney = this.props.history.location.pathname;
        switch (formId) {
            case "Avaloqidform":
                this.RegistrationComp.jumpTo("ENTER_DETAILS");
                return this.state.registrationComponents.EnterDetails;
            case "RegUserForm":
            case "RegPasswordForm":
                this.RegistrationComp.jumpTo("CREATE_USERNAME_PASSWORD");
                return this.state.registrationComponents.CreateUsernamePassword;
            case "Agreementform":
                if (currentJourney.indexOf("logon") !== -1) {
                    const isRegistration = sessionStorage.getItem(
                        "isRegistration"
                    );
                    if (isRegistration) {
                        return this.state.logonComponents.InputCredential;
                    } else {
                        this.LogonComp.jumpTo("ACCEPT_TERMS");
                        return this.state.logonComponents.AcceptTerms;
                    }
                } else {
                    this.RegistrationComp.jumpTo("ACCEPT_TERMS");
                    return this.state.registrationComponents.AcceptTerms;
                }
            case "AliasOnlyform":
                if (currentJourney === isResetPassword) {
                    this.ResetPasswordComp.jumpTo("FORGOT_PASSWORD");
                    return this.state.resetPasswordComponents.ForgotPassword;
                }
                return null;
            case "GetResetPassword":
                if (currentJourney === isResetPassword) {
                    this.ResetPasswordComp.jumpTo("CREATE_PASSWORD");
                    return this.state.resetPasswordComponents
                        .StepCreatePassword;
                }
                return null;
            case "e2ekeyform":
                return this.state.logonComponents.InputCredential;
            case "PushNotification_CheckMobileScreen":
                this.LogonComp.jumpTo("CHECK_MOBILE");
                return this.state.logonComponents.CheckMobile;
            case "mock_password":
                this.props.isMockLoginJourney(true);
                return this.state.logonComponents.InputCredential;
            case "mock_otp":
                this.props.isMockLoginJourney(true);
                this.LogonComp.jumpTo("GENERIC_OTP");
                return this.state.logonComponents.GenericOTP;
            default:
                return null;
        }
    };

    createMobileApproveAuthSession = (type, state, instructions) => {
        this.LogonComp.jumpTo("CHECK_MOBILE");
        return this.state.logonComponents.CheckMobile;
    };

    createOtpAuthSession = (
        stepName,
        userName,
        _possibleTargets,
        _lastSelectedTarget
    ) => {
        const currentJourney = this.props.history.location.pathname;

        if (currentJourney === "/registration") {
            this.RegistrationComp.jumpTo("GENERIC_OTP");
            return this.state.registrationComponents.GenericOTP;
        }
        if (currentJourney === "/logon") {
            this.LogonComp.jumpTo("GENERIC_OTP");
            return this.state.logonComponents.GenericOTP;
        }
        if (currentJourney === isResetPassword) {
            this.ResetPasswordComp.jumpTo("GENERIC_OTP");
            return this.state.resetPasswordComponents.GenericOTP;
        }
        return null;
    };
    createEscapeRequest = actionContext => {
        const escapeoptions = actionContext.getEscapeOptions();
        return Promise.resolve(
            window.com.ts.mobile.sdk.AuthenticatorSelectionResult.createEscapeRequest(
                escapeoptions[0],
                {}
            )
        );
    };
    selectAuthenticator = (options, actionContext, clientContext) => {
        const currentJourney = this.props.history.location.pathname;

        if (currentJourney === "/logon") {
            const escapeoptions = actionContext.getEscapeOptions()[0];
            return Promise.resolve(
                window.com.ts.mobile.sdk.AuthenticatorSelectionResult.createEscapeRequest(
                    escapeoptions,
                    { raw_data: "fail" }
                )
            );
        }
        if (currentJourney === isResetPassword) {
            if (
                this.props.lockedType === "OTP" ||
                this.props.lockedType === "QUESTION"
            ) {
                this.props.resetState();
                return this.createEscapeRequest(actionContext);
            } else {
                return Promise.resolve(
                    window.com.ts.mobile.sdk.AuthenticatorSelectionResult.createSelectionRequest(
                        options[0]._authenticator
                    )
                );
            }
        }
        return Promise.reject();
    };

    createSecurityQuestionAuthSession = (title, username) => {
        const currentJourney = this.props.history.location.pathname;
        if (currentJourney === isResetPassword) {
            this.ResetPasswordComp.jumpTo("SECURITY_QUESTION");
            return this.state.resetPasswordComponents.SecurityQuestion;
        }
        if (currentJourney === "/registration") {
            this.RegistrationComp.jumpTo("CREATE_SECURITY_QUESTION");
            return this.state.registrationComponents.createSecurityQuestions;
        }
        return null;
    };

    existingSessions = () => {
        const existingSession = _.get(
            window.transmitSDK,
            currentSessionId,
            false
        );
        if (existingSession) {
            makeLogoutRequest("Registration");
        }
    };

    caseRegistrationError = (err, that) => {
        if (
            _.get(err, "_data.failure_data.reason.data.status", false) ===
            "END_OF_REGISTRATION"
        ) {
            that.jumpTo("REGISTRATED_SUCCESSFUL");
            return;
        }

        const errorMsg = _.get(err, "_data.failure_data.reason.data", false);
        console.log("errorMsg", errorMsg);
        if (errorMsg && errorMsg.reason === "LISTED_IN_SANCTION_COUNTRY") {
            history.push("/restrictedAccess");
            return;
        }

        if (err._errorCode === 16) {
            history.push("/noContactDetails");
            return;
        }
        if (
            errorMsg &&
            errorMsg.reason &&
            errorMsg.reason === "USER_ALREADY_REGISTERED"
        ) {
            history.push("/logon");
            this.props.dispatchAction({
                type: "VALIDATE_DETAILS_SUCCESS",
                validateDetails: {
                    errorType: "registrated",
                    statusText: (
                        <FormattedMessage id="inputCredential.label.alreadyRegistered" />
                    )
                }
            });
            return;
        }
        if (
            err._errorCode &&
            err._errorCode === 7 &&
            err._message === "Operation cancelled by user."
        ) {
            console.log("user give up");
            return;
        }
        const isOtpError = sessionStorage.getItem("isOtpError");
        if (!isOtpError) {
            history.push({
                pathname: "/globalError/noConnection?step=005",
                state: { errorCode: "noConnection" }
            });
        }

        console.log(
            err,
            "Registration Journey Authentication error: " +
                (err.getMessage && err.getMessage()) || "Unknown"
        );
    };

    journeyRegistrationStart = (components, that) => {
        this.RegistrationComp = that;
        this.setState({
            registrationComponents: components
        });
        window.clientContext = {};
        this.props.resetState();
        this.prepareSDK()
            .then(sdk => {
                this.existingSessions();
                sdk.clearAllData();
                sdk.invokeAnonymousPolicy(
                    "register_anonymouspart",
                    {},
                    window.clientContext
                ).then(
                    function() {
                        console.log(
                            "journeyRegistrationStart::then suc",
                            "Registration successful!"
                        );

                        console.log(
                            "SOMETHING WENT WRONG - SESSION NOT KILLED"
                        );
                        that.jumpTo("REGISTRATED_SUCCESSFUL");
                    },
                    err => {
                        this.caseRegistrationError(err, that);
                    }
                );
            })
            .catch(err => {
                console.error(
                    "journeyRegistrationStart::catch",
                    err.toString()
                );
                history.push({
                    pathname: "/globalError/noConnection?step=006",
                    state: { errorCode: "noConnection" }
                });
            });
    };

    journeyLogonStart = (components, that) => {
        this.LogonComp = that;
        this.props.resetState();

        this.initRegistrationSdk();
        this.setState({
            logonComponents: components
        });
    };

    caseResetPasswordErrorcode = (err, lockedCheck, reasonMsg) => {
        if (err && err._errorCode === 16) {
            history.push("/noContactDetails");
            return true;
        } else if (
            (err && err._errorCode === 3) ||
            lockedCheck === "LOCKED_GLOBAL" ||
            lockedCheck === "LOCKED_OTP" ||
            lockedCheck === "LOCKED_QUESTION"
        ) {
            history.push({
                pathname: pathForGenericLock,
                state: { errorCode: "genericAccountLock" }
            });
            return true;
        } else if (
            err._data &&
            err._data.additional_data &&
            err._data.additional_data.locked === true
        ) {
            console.log("user lockedType: ", this.props.lockedType);
            if (this.props.lockedType === "OTP") {
                console.log("reset password OTP-lock yes!!");
                history.push({
                    pathname: pathForGenericLock,
                    state: { errorCode: "genericAccountLock" }
                });
                return true;
            } else {
                console.log("reset password QUESTION-lock yes!!");
                history.push({
                    pathname: pathForGenericLock,
                    state: { errorCode: "genericAccountLock" }
                });
                return true;
            }
        } else if (
            err &&
            err._errorCode &&
            err._errorCode === 7 &&
            err._message === "Operation cancelled by user."
        ) {
            console.log("user give up");
            return false;
        } else {
            console.log(
                "ResetPassword Journey Authentication error: " + reasonMsg
            );
            return false;
        }
    };

    caseResetPasswordError = (err, toDisplayUserErrorMsg) => {
        const isResetCompelte = _.get(
            err,
            "_data.failure_data.reason.data.status",
            false
        );
        if (isResetCompelte === "RESET_COMPLETE") {
            history.push("/logon?resetPasswordSuc");
            this.props.dispatchAction({
                type: "RESET_PASSWORD_CREATE_PASSWORD_SUC"
            });
            return;
        }
        console.log("resetpassword journey catch handler got err: ", err);

        const sanctioned = _.get(
            err.getData(),
            "failure_data.reason.data.reason",
            false
        );

        if (sanctioned === "LISTED_IN_SANCTION_COUNTRY") {
            history.push("/restrictedAccess");
            return;
        }

        const reasonMsg = (err.getMessage && err.getMessage()) || "Unknown";
        const lockedCheck = _.get(
            err,
            '_data.failure_data.reason.data["LOCK-CHECK"]',
            false
        );

        const failReason = _.get(
            err.getData(),
            "failure_data.reason.data.reason",
            false
        );

        if (
            failReason === "USERNAME_DOES_NOT_EXIST" ||
            failReason === "USER_NOT_RECOGNISED"
        ) {
            toDisplayUserErrorMsg && toDisplayUserErrorMsg();
            return;
        }

        if (this.caseResetPasswordErrorcode(err, lockedCheck, reasonMsg)) {
            return;
        }
        console.log(failReason, "failReason");
        if (failReason === "INVALID_CREDENTIALS") {
            this.props.isLoading(false);
            history.push("/resetPassword?invalidCredentials");
            this.ResetPasswordComp.jumpTo("FORGOT_PASSWORD");
            return;
        }
        if (failReason === "ACCOUNT_LOCKED") {
            history.push({
                pathname: pathForGenericLock,
                state: { errorCode: "genericAccountLock" }
            });
            return;
        }
        history.push({
            pathname: "/globalError/noConnection?step=007",
            state: { errorCode: "noConnection" }
        });
    };

    invokeResetPasswordPolicy = (username, toDisplayUserErrorMsg) => {
        const appComp = this;
        window.transmitSDK
            .authenticate(username, "reset_pwd_policy", {}, {})
            .then(
                successfulAuth => {
                    history.push("/logon?resetPasswordSuc");
                    appComp.props.dispatchAction({
                        type: "RESET_PASSWORD_CREATE_PASSWORD_SUC"
                    });
                },
                err => {
                    this.caseResetPasswordError(err, toDisplayUserErrorMsg);
                }
            );
    };
    journeyResetPasswordStart = (components, that) => {
        this.ResetPasswordComp = that;

        this.setState({
            resetPasswordComponents: components
        });
        this.props.resetState();
        this.prepareSDK().then(sdk => {
            const existingSession = _.get(
                window.transmitSDK,
                currentSessionId,
                false
            );

            if (existingSession) {
                makeLogoutRequest("Reset Password");
            }

            sdk.clearAllData();
        });
    };

    createPasswordAuthSession = (title, username) => {
        const currentJourney = this.props.history.location.pathname;
        if (currentJourney === "/logon") {
            this.LogonComp.jumpTo("INPUT_CREDENTIAL");
            return this.state.logonComponents.InputCredential;
        }
        return null;
    };

    shouldIncludeDisabledAuthenticatorsInMenu() {
        return false;
    }
    startActivityIndicator = () => {
        console.log("startActivityIndicator");
    };
    endActivityIndicator = () => {
        console.log("endActivityIndicator");
    };
    controlFlowStarting = () => {
        console.log("controlFlowStarting");
    };
    controlFlowEnded = () => {
        console.log("controlFlowEnded");
    };
    controlFlowCancelled = () => {
        console.log("controlFlowCancelled");
    };
    controlFlowActionStarting = () => {
        console.log("controlFlowActionStarting");
    };
    controlFlowActionEnded = () => {
        console.log("controlFlowActionEnded");
    };
    getInformationResponse = () => {
        return Promise.resolve(window.com.ts.mobile.sdk.ConfirmationInput.create(-1));
    };

    getConfirmationResponse = () => {
        return Promise.resolve(window.com.ts.mobile.sdk.ConfirmationInput.create(0));
    };

    handlePolicyRedirect = (
        redirectType,
        policyId,
        userId,
        additionalParameters,
        clientContext
    ) => {
        console.log(
            `Policy redirection requested. redirecting to policy: [${policyId}] 
            with additionalParameters: [${JSON.stringify(
                additionalParameters
            )}] redirectType [${redirectType}]`
        );
        return Promise.resolve(
            window.com.ts.mobile.sdk.RedirectInput.create(
                window.com.ts.mobile.sdk.RedirectResponseType.RedirectToPolicy
            )
        );
    };

    handlePolicyRejection = (
        title,
        text,
        buttonText,
        failureData,
        actionContext,
        clientContext
    ) => {
        console.log(
            `Policy rejection: [title] ${title}; [text] ${text} [failureData] ${JSON.stringify(
                failureData
            )}`
        );

        return Promise.resolve(
            window.com.ts.mobile.sdk.ConfirmationInput.create(-1)
        );
    };

    processJsonData(jsonData, actionContext, clientContext) {
        if (jsonData && jsonData.guid) {
            localStorage.setItem("guid", jsonData.guid);
        }
        console.log("jsonData: ", jsonData);

        if (jsonData.attempt === "last" || jsonData.isLastAttempt === "true") {
            this.props.isLastAttemptTime(true);
        }

        if (jsonData.maskedEmailAddressWeb) {
            this.props.emailOtpLit(jsonData.maskedEmailAddressWeb);
        }

        if (isRealmCH && jsonData && jsonData.defaultAuth) {
            this.props.updateCurrentOTPType(jsonData.defaultAuth);
        }

        clientContext.jsonData = jsonData;
        return Promise.resolve(
            window.com.ts.mobile.sdk.JsonDataProcessingResult.create(true)
        );
    }

    render() {
        const currentUrlPathIsNotLogon =
            window.location.href.indexOf("logon") === -1;
        const showHeader =
            currentUrlPathIsNotLogon ||
            (envConfig.languageSlectList &&
                envConfig.languageSlectList.length > 1);
        const isInUS = envConfig.realm === "US" ? true : false;
        const isCCM =
            envConfig.realm === "UK" ||
            envConfig.realm === "LU" ||
            envConfig.realm === "CI"
                ? true
                : false;
        const showBackgroundImg =
            window.location.href.indexOf("cookieNotice") !== -1 ? false : true;
        return (
            <AppContainer>
                <AppHeader
                    currentUrlPathIsShowHeaderFooter={currentUrlPathIsNotLogon}
                />
                <Router history={history}>
                    <ContentContainer showHeader={showHeader} isInUS={isInUS}>
                        <ParentContainer
                            showHeader={showHeader}
                            isInUS={isInUS}
                        >
                            {showBackgroundImg ? (
                                <AppBackgroundKaleido
                                    currentUrlPathIsNotLogon={
                                        currentUrlPathIsNotLogon
                                    }
                                />
                            ) : null}

                            <Switch>
                                <Route
                                    exact
                                    path="/logon"
                                    render={() => (
                                        <Logon
                                            afterLoad={this.journeyLogonStart}
                                            currentStep={
                                                this.state.currentLogonStep
                                            }
                                            history={history}
                                            match={this.props.match}
                                        />
                                    )}
                                />
                                <Route
                                    path="/logon/:id"
                                    render={() => (
                                        <Logon
                                            afterLoad={this.journeyLogonStart}
                                            currentStep={
                                                this.state.currentLogonStep
                                            }
                                            history={history}
                                            match={this.props.match}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/resetPassword"
                                    render={() => (
                                        <ResetPassword
                                            afterLoad={
                                                this.journeyResetPasswordStart
                                            }
                                            currentStep={
                                                this.state
                                                    .currentResetpasswordStep
                                            }
                                            invokeResetPasswordPolicy={
                                                this.invokeResetPasswordPolicy
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/registration"
                                    render={() => (
                                        <Registration
                                            afterLoad={
                                                this.journeyRegistrationStart
                                            }
                                            currentStep={
                                                this.state
                                                    .currentRegistrationStep
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/restrictedAccess"
                                    component={RestrictedAccess}
                                />
                                <Route
                                    exact
                                    path="/unsupportedBrowser"
                                    component={UnsupportedBrowser}
                                />
                                <Route
                                    path="/error/:errorCode"
                                    component={ErrorCode}
                                />
                                <Route
                                    path="/globalError/:errorCode"
                                    component={CommonLockedAccount}
                                />
                                <Route
                                    exact
                                    path="/noContactDetails"
                                    component={NoContactDetails}
                                />
                                <Route
                                    path="/commonLockedAccount/:errorCode"
                                    component={CommonLockedAccount}
                                />
                                <Route
                                    exact
                                    path="/acceptTerms"
                                    component={AcceptTerms}
                                />
                                <Route
                                    path="/loginHelper/:AMSESSION/:clientId/:accountStatus?"
                                    component={LoginHelper}
                                />
                                <Route
                                    exact
                                    path="/onlyEnglishTips"
                                    component={ModalPage}
                                />
                                <Route
                                    exact
                                    path="/gvResult"
                                    component={GvResult}
                                />
                                <Route
                                    exact
                                    path="/gvError"
                                    component={GvError}
                                />
                                <Route
                                    exact
                                    path="/gvSSOError"
                                    component={GvSSOError}
                                />
                                <Route
                                    exact
                                    path="/gvCamError"
                                    component={GvMessage}
                                />
                                <Route
                                    exact
                                    path="/cookieNotice"
                                    component={CookieNotice}
                                />
                                <Route path="/:id">
                                    <Redirect from="/:id" to="/logon" />
                                </Route>
                            </Switch>
                        </ParentContainer>
                    </ContentContainer>
                </Router>
                <AppFooter isCCM={isCCM} />
                <LanguageSelectContainer
                    selectedLang={this.props.selectedLang}
                ></LanguageSelectContainer>
            </AppContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(App));

const AppContainer = styled.div`
    height: 100%;
    margin: auto;
    overflow: auto;
    position: relative;

    background-color: #fff;

    .footer-bottom {
        font-weight: bold;
    }
    .footer-us {
        p {
            margin-bottom: 0px;
            margin-top: 0px;
        }
    }
`;

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    min-height: calc(100vh - 120px);
`;
const ParentContainer = styled.div`
    width: 100%;
    font-size: 12px;
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - 120px);
    padding-bottom: ${props => (props.showHeader ? "0px" : "5px")};
    @media (min-width: 1020px) {
        padding-left: 6.94%;
    }

    @media (max-width: 1019px) {
        align-items: center;
    }

    .registration-success {
        position: absolute;
        z-index: 2;
        top: -60px;
        left: 0;
        width: 100%;
        height: calc(100vh);
        background: rgba(63, 79, 93, 0.85);
        @media (max-width: 350px) {
            top: -15%;
            height: 130%;
        }
    }
`;
