import React, { Component } from "react";
import { envConfig } from "env-config";
import styled from "styled-components";
import axios from "axios";
import { guid } from "util/service";
import { Loader } from "hsbc-pb-sdk";

const isLowerEnv =
    window.location.href.includes("localhost") ||
    window.location.href.includes("hkl20042377");

export default class LoginHelper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            pagestate: "",
            errorReason: "",
            accountStatus: null,
            errorCode: null
        };
    }

    receiveMessage = event => {
        if (event.source === window.top) {
            console.log("new event received", event);
        }
    };

    postMessage = (origin, data) => {
        if (origin) {
            origin.postMessage(data, "*");
        }
    };
    componentDidUpdate = prevState => {
        if (
            prevState.pagestate !== this.state.pagestate &&
            this.state.pagestate !== ""
        ) {
            const pageStatus = {
                status: this.state.pagestate
            };
            this.postMessage(window.top, pageStatus);
        }
    };

    fetchSimulate = (AMSESSION, clientId) => {
        const { accountStatus } = this.props.match.params;
        const uuid = guid();
        const opts = {
            method: "GET",
            headers: {
                OIDCFilter: true,
                AMSESSION,
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            }
        };
        if (!isLowerEnv) {
            opts.headers["X-HSBC-Request-Correlation-Id"] = uuid;
        }
        axios(
            `${envConfig.protocol}://${envConfig.url}/rest/hsbc/clientprofile/clients/${clientId}/simulate`,
            opts
        )
            .then(data => {
                this.setState({
                    pagestate: "SUCCESSED"
                });
                if (accountStatus) {
                    const iframeSrc = `${envConfig.protocol}://${envConfig.url}${envConfig.wvUri}`;
                    this.postMessage(window.top, { iframeSrc });
                } else {
                    window.location.href = `${envConfig.protocol}://${envConfig.url}${envConfig.wvUri}`;
                }
            })
            .catch(error => {
                this.setState({
                    pagestate: "ERROR",
                    errorReason: error.response
                        ? "Error code: " + error.response.status
                        : "Error: " + error.message,
                    accountStatus,
                    errorCode: error.response ? error.response.status : ""
                });
            });
    };

    fetchSession = () => {
        const { AMSESSION, clientId, accountStatus } = this.props.match.params;
        const uuid = guid();
        const opts = {
            method: "POST",
            headers: {
                OIDCFilter: true,
                AMSESSION,
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            data: JSON.stringify({
                amSession: AMSESSION
            })
        };
        if (!isLowerEnv) {
            opts.headers["X-HSBC-Request-Correlation-Id"] = uuid;
        }
        axios(
            `${envConfig.protocol}://${envConfig.url}/simulation/session`,
            opts
        )
            .then(data => {
                this.fetchSimulate(AMSESSION, clientId);
            })
            .catch(error => {
                this.setState({
                    pagestate: "ERROR",
                    errorReason: error.response
                        ? "Error code: " + error.response.status
                        : "Error: " + error.message,
                    errorCode: error.response ? error.response.status : "",
                    accountStatus
                });
            });
    };

    componentDidMount() {
        const loadingTextAria = document.getElementById("loading-text-aria");
        if (loadingTextAria) {
            loadingTextAria.focus();
        }
        window.addEventListener("message", this.receiveMessage, false);
        this.setState({
            pagestate: "LOAD"
        });
        const REALM = envConfig.realm;
        if (envConfig.multiTenancy) {
            document.cookie =
                `realm=${REALM}` +
                `; path=/; domain=${envConfig.id}; sameSite=None; Secure`;
        }
        this.fetchSession();
    }

    render() {
        return (
            <LoadingContainer>
                {this.state.pagestate === "ERROR" ? (
                    <div className="error">
                        <div
                            className="error-text"
                            id="error-text-aria"
                            tabIndex={1}
                        >
                            <div className="error-icon">
                                <span className="icon icon--triangle-error" />
                            </div>
                            {this.state.accountStatus &&
                            this.state.accountStatus.toLowerCase() ===
                                "inactive" &&
                            this.state.errorCode === 403 ? (
                                <div>
                                    <div className="error-text-strong">
                                        Error
                                    </div>
                                    <div className="error-text-small">
                                        Unfortunately, the simulation feature is
                                        unavailable as the client whose account
                                        you are attempting
                                    </div>
                                    <div className="error-text-small">
                                        to view appears to be inactive at
                                        present. Please check the status of
                                        their account and refer to the Digital
                                        Access
                                    </div>
                                    <div className="error-text-small">
                                        Manager’s Processes and Procedures
                                        should you need more information.
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="error-text-strong">
                                        Error
                                    </div>
                                    <div className="error-text-small">
                                        Sorry, there’s been a problem loading
                                        your data.
                                    </div>
                                    <div className="error-text-small">
                                        Please close the screen using the button
                                        at the top right and try again.
                                    </div>
                                    <div className="error-text-small">
                                        If the problem persists, please contact
                                        IT support.
                                    </div>
                                </div>
                            )}
                            <div className="error-text-code">
                                {this.state.errorReason}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="load">
                        <div className="load-container">
                            <Loader
                                className="simulate-loader"
                                size="huge"
                            ></Loader>
                        </div>
                        <div
                            className="loading-text"
                            id="loading-text-aria"
                            tabIndex={0}
                            role="text"
                            aria-label={`We are loading your simulation session,please wait...This may take a 
                                few moments. Once the simulation has loaded you can close it at any time using the button at the top right.`}
                        >
                            <div className="loading-text-strong">
                                <div>
                                    We are loading your simulation session,
                                </div>
                                <div>please wait...</div>
                            </div>
                            <div className="loading-text-small">
                                This may take a few moments. Once the simulation
                                has loaded you can close it at any time using
                                the button at the top right.
                            </div>
                        </div>
                    </div>
                )}
            </LoadingContainer>
        );
    }
}

const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 100px;
    color: #000;
    .load {
        text-align: center;
        .load-container {
            position: relative;
            height: 120px;
        }
    }
    .loading-text {
        text-align: center;
        display: inline-block;
        margin: auto;
        &-strong {
            font-size: 20px;
        }
        &-small {
            margin-top: 10px;
        }
    }
    .error {
        @media (min-width: 1024px) {
            margin-left: -6%;
            height: 100%;
            margin-top: -100px;
            vertical-align: middle;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media (max-width: 1023px) {
            text-align: center;
            height: 100%;
            margin-top: -100px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 11;
        }

        @media (max-width: 460px) {
            margin-top: 10px;
        }

        .error-icon {
            color: red;
            font-size: 50px;
        }
        .error-text {
            display: inline-block;
            text-align: center;
            &-strong {
                margin-top: 10px;
                font-size: 20px;
            }
            &-small {
                margin-top: 5px;
            }
            &-code {
                margin-top: 10px;
            }
        }
    }
`;
